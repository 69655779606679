import { Avatar } from '@progress/kendo-react-layout';
import { IconRegular } from 'Theme/Icons';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from 'Components/Core/Services/Hooks/AuthenticationHook';

import { useMediaBreakpointDown, useTouchDevice } from '../Hooks/LayoutHook';
import { useActiveTransaction } from '../Transaction/Hooks/TransactionHook';

import { Breadcrumb } from './Breadcrumb/BreadcrumbComponent';
import { Finder } from './FinderComponent';
import { INavTopbarProps } from './INavTopbarProps';
import whiteLogo from './tmsWhiteLogo.png';

import './NavTopbarTheme.scss';

const TMS_TITLE = 'TMS';

/**
 * Application top bar component.
 * @param {INavTopbarProps} props
 * @returns NavTopbar
 */
export const NavTopbar: FunctionComponent<INavTopbarProps> = ({ toggleUserSidebar }: INavTopbarProps) =>
{
    // #region Hooks
    const currentUser = useCurrentUser();
    const touchDevice = useTouchDevice();
    const smallDevice = useMediaBreakpointDown('sm');
    const { activeTransaction } = useActiveTransaction();
    const { t } = useTranslation('/Core/Layout/Layout');
    // #endregion

    // #region Render
    const functionKey = !['I000000', 'I000001', 'I000404'].some(value => value === activeTransaction.id) && activeTransaction.id.length >= 1 ?
        activeTransaction.id[0]
        : '';

    const headerTitle = useMemo(() =>
        smallDevice ?
            (activeTransaction.id !== '' ?
                `${activeTransaction.id} - ${activeTransaction.title}`
                : TMS_TITLE)
            : TMS_TITLE,
    [activeTransaction.id, activeTransaction.title, smallDevice]);

    return (
        <>
            <nav className={`k-appbar k-appbar-dark ${functionKey} ${touchDevice ? 'touch' : ''}`} id="navTopbar">
                <div className='k-appbar-section'>
                    <img src={whiteLogo} alt="TMS Logo" />
                </div>

                <div className='k-appbar-section'>
                    <h1 title={headerTitle}>{TMS_TITLE}</h1>
                </div>

                <Breadcrumb className="k-appbar-section" />

                <span className="k-appbar-spacer d-inline-flex" />

                <Finder className="k-appbar-section"/>

                <div className='k-appbar-section d-none d-sm-inline-flex personCompanyName'>
                    <h2 title={currentUser.companyName}>{currentUser.companyName}</h2>
                    <h3 title={currentUser.personName}>{currentUser.personName}</h3>
                </div>

                <div className="k-appbar-section">
                    <div className="avatar"
                        title={t('navTopbar.userSidebar')}
                        onClick={toggleUserSidebar}>
                        <Avatar rounded="full" type="image" themeColor="light" fillMode='outline'>
                            <span className={IconRegular.userCircle}></span>
                        </Avatar>
                    </div>
                </div>
            </nav>
        </>
    );
    // #endregion
};